import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import MLBOpponent from './mlbOpp';
import Radar from '../charts/radar';
import RankCircle from '../charts/rankCircle';
import SwipeableTabs from './tabs';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const FANDUEL_LINK = 'https://account.pa.sportsbook.fanduel.com/join#RAF=Jeffrey_9259183';
const DRAFTKINGS_LINK = 'https://sportsbook.draftkings.com/r/sb/dfspython/US-PA-SB';

const useStyles = makeStyles((theme) => ({
  rankGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    // gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  },
  '@media screen and (max-width: 960px)': {
    rankGrid: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
  },
  formControl: {
    minWidth: '100px',
    padding: '10px',
  },
  lineChart: {
    color: 'black',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subHeader: {
    color: '#51ade5',
    fontFamily: 'Audiowide',
  },
  accordion: {
      backgroundColor: "white",
  },
  detail: {
      color: "gray",
  },
  header: {
    textShadow: '1px 1px 2px #264d66',
    color: 'white',
    backgroundColor: "#51ade5",
  },
  odds: {
    background: '#ffffff55',
    textAlign: 'center',
    alignItems: 'center',
    display: 'grid',
    padding: '4px 10px',
    borderRadius: '4px',
    marginRight: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'white',
    fontFamily: 'Audiowide',
  },
  focusedStat: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'grid',
    padding: '4px 10px',
    color: '#50ade5',
    fontFamily: 'Audiowide',
  },
  divLink: {
    textDecoration: 'none',
    display: 'contents',
  },
  dk: {
    backgroundImage: 'url(logos/dk_25.svg)',
  },
  fd: {
    backgroundImage: 'url(logos/fd_25.svg)',
  },
  content: { background: '#eeeeee' },
  titleWrapper: { display: 'flex', marginRight: '30px' },
  focusedWrapper: {
    display: 'flex',
    justifyContent: 'space-between', 
    paddingTop: '16px',
  },
  red: { background: '#FF000030' },
  green: { background: '#00800030' },
  input: { width: '50px' },
  grid: {
    display: "grid",
    columnGap: "10px",
    gridTemplateColumns: "auto 1fr auto",
  },
}));

const convertToPercent = (value) => {
  return `${Math.round(value * 100)}%`;
}

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { dkOdds, fdOdds, gameLine } = props.data;

  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.titleWrapper}>
        <Typography variant="h6">{children}</Typography>
        <div style={{ marginLeft: 'auto' }}/>
        {gameLine && (
          <div className={classes.odds}>
            <div>{gameLine.spread}</div>
            <div>{gameLine.totalPoints}</div>
          </div>
        )}
        {dkOdds && (
          <a className={classes.divLink} target='blank' href={DRAFTKINGS_LINK}>
            <div className={`${classes.odds} ${classes.dk}`}>
              <div>{dkOdds.over}</div>
              <div>{dkOdds.line}</div>
              <div>{dkOdds.under}</div>
            </div>
          </a>
        )}
        {fdOdds && (
          <a className={classes.divLink} target='blank' href={FANDUEL_LINK}>
            <div className={`${classes.odds} ${classes.fd}`}>
              <div>{fdOdds.over}</div>
              <div>{fdOdds.line}</div>
              <div>{fdOdds.under}</div>
            </div>
          </a>
        )}
      </div>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

const renderTableHeaders = (sport, type, headers=[]) => {
  if (sport === 'nba') {
    return (
      <tr key="header">
        <th>Date</th>
        <th>Opp</th>
        <th>Result</th>
        <th>Opp Rnk</th>
        <th>Started</th>
        <th>{type}</th>
        <th>Minutes</th>
      </tr>
    );
  } else if (sport === 'mlb') {
    return (
      <tr key="header">
        {headers.map(header => <th key={header}>{header}</th>)}
        <th>Rank</th>
      </tr>
    );
  } else if (headers.length) {
    return (
      <tr key="header">
        {headers.map(header => <th key={header}>{header}</th>)}
      </tr>
    );
  } else {
    return (
      <tr key="header">
        <th>{sport === 'nfl' ? 'Week' : 'Date'}</th>
        <th>Opp</th>
        <th>{sport === 'nfl' ? 'Opp Rank' : sport === 'nba' ? 'Points' : 'K Rate'}</th>
        {/* <th>{firstStat.label}</th>
        <th>{secondStat.label}</th> */}
        {type.includes('rec') && (
          <th>Targets</th>
        )}
        <th>Snaps</th>
      </tr>
    )
  }
}
  
const renderGameLogTable = (a, sport, type, columns=[], ou, classes) => {
  if (sport === 'nba') {
    let className;
    if (ou && a[type]) className = a[type] > ou ? 'green' : 'red';
    return (
      <tr key={a.date}>
        <td>{a.date}</td>
        <td>{a.opp}</td>
        <td dangerouslySetInnerHTML = {{ __html: a.score }} />
        <td>{a.oppRank}</td>
        <td>{a.started}</td>
        <td className={classes[className]}>{a[type]}</td>
        <td>{a.min}</td>
      </tr>
    );
  } else if (sport === 'mlb') { 
    let className;
    if (ou && a[type]) className = a[type] > ou ? 'green' : 'red';
    return (
      <tr key={a.date || a.season}>
        {columns.map(column => 
          <td className={a[column] === a[type] ? classes[className] : ''} key={column}>
            { Array.isArray(column) ? Math.round(a[column[0]] / a[column[1]] * 1000) / 1000 : a[column]}
          </td>
        )}
        <td>{a.oppDetails && a.oppDetails.kRate_rank}</td>
      </tr>
    );
  } else if (columns.length) { 
    let className;
    if (ou && a[type]) className = a[type] > ou ? 'green' : 'red';
    return (
      <tr key={a.week || a.date || a.season}>
        {columns.map(column => 
          <td className={a[column] === a[type] ? classes[className] : ''} key={column}>
            { Array.isArray(column) ? Math.round(a[column[0]] / a[column[1]] * 10) / 10 : a[column] }
          </td>
        )}
      </tr>
    );
  } else {
    return (
      <tr key={a.week}>
        <td>{sport === 'nfl' ? a.week : a.date}</td>
        <td>{a.opp}</td>
        <td>{sport === 'nfl' ? a.standardDef : sport === 'nba' ? a.pts : (a.strikeOuts / a.atBats).toFixed(3) }</td>
        {/* <td>{a[firstStat.value]}</td>
        <td>{a[secondStat.value]}</td> */}
        {type.includes('rec') && (
          <td>{a.targets}</td>
        )}
        <td>{a.snapsOff}</td>
      </tr>
    );
  }
}

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;
  return <span style={{ color }}>{value.toUpperCase()}</span>;
};

const getYear = (year, current, previous) => {
  if (year === 'currentYear') return  current;
  else return previous;
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomizedDialogs = (props) => {
  const { modalVisible, useSeasonStats, setModalVisible, data, propType, type, sport, columns, headers, meta = [], teamRanks = [], maxSliderValue, focusedStats, xAxis } = props,
    { playerName, opp, oppDefRank, oppStats, playerNews, playerAnalysis, pos, PH, gameLog, gameLog2023, seasons, lineup, seasonStats, careerStats } = data;

  const [ year, setYear ] = useState('currentYear');
  const classes = useStyles();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  let initialOU = data["O/U"] || 0;
  const [ouValue, setOUValue] = React.useState(0);
  
  const getHitRate = (ouValue) => {
    let numOver = 0,
      numTotal = 0;

    if (useSeasonStats) {
      seasons.forEach(season => {
        if (season[type] > ouValue) numOver++;
        numTotal++;
      });
    } else {
      (gameLog || []).forEach(game => {
        if (!game.dnp && game.opp !== "BYE") {
          if (game[type] > ouValue) numOver++;
          numTotal++;
        }
      });
    }

    return convertToPercent(numOver/numTotal);
  };

  useEffect(() => {
    setOUValue(initialOU);
  }, [initialOU]);
  
  const handleSliderChange = (event, newValue) => {
    setOUValue(newValue);
  };

  const handleInputChange = (event) => {
    setOUValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (ouValue < 0) {
      setOUValue(0);
    } else if (ouValue > maxSliderValue) {
      setOUValue(maxSliderValue);
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  // let log = gameLog || [];
  (gameLog || []).sort((a, b) => parseFloat(a.date) - parseFloat(b.date));
  let gameLogDesc = gameLog ? [...gameLog].reverse() : [];

  (seasons || []).sort((a, b) => parseFloat(a.season) - parseFloat(b.season));
  // let seasonDesc = seasons ? [...seasons].reverse() : [];

  if(seasonStats) {
    seasonStats.reduce((accumulator, current) => {
      // Combine seasons that span multiple teams into one row
      if(accumulator[accumulator.length - 1] && current.season === accumulator[accumulator.length - 1].season) {
        accumulator.pop()
        return [...accumulator, current];
      } 
      else return [...accumulator, current];
    }, []);
  }

  return (
    <div>
      { modalVisible && (
        <Dialog key="detailDialog" maxWidth={'md'} fullWidth={true} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible}>
          <DialogTitle data={data} className={classes.header} id="customized-dialog-title" onClose={handleClose}>
            {playerName} {pos || PH} <br/>
            O/U: {data["O/U"]} {propType} <br/>
            {typeof opp === 'string' && (
              <>
                Opp: {opp}
                {oppDefRank && (
                  <> Rnk: {oppDefRank}</>
                )}
              </>
            )}
            {typeof opp === 'object' && (
              <>
                Opp: {opp.teamName}
                {opp.stats && (
                  <> Rnk: {opp.stats.kPerGame_rank}</>
                )}
              </>
            )}
          </DialogTitle>
          <DialogContent className={classes.content} dividers>
              <div className={classes.grid}>
                <Select
                    labelId="year-select-placeholder-label-label"
                    id="year-select-placeholder-label"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="currentYear">2024</MenuItem>
                    <MenuItem value="lastYear">2023</MenuItem>
                </Select>
                <Slider
                  value={typeof ouValue === 'number' ? ouValue : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  step={0.5}
                  max={maxSliderValue}
                  // valueLabelDisplay="on"
                />
                <Input
                  className={classes.input}
                  value={ouValue}
                  margin="dense"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: maxSliderValue,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                />
              </div>
              { focusedStats && (
                <div className={classes.focusedWrapper}>
                    {focusedStats.map(stat => {
                      let val;
                      if (stat.value) val = stat.isOU ? ouValue : data[stat.value];
                      else {
                        let num = stat.isOU ? ouValue : data[stat.formula[0]],
                          den = isNaN(stat.formula[1]) ? data[stat.formula[1]] : stat.formula[1];
                          
                        val = Math.round(num/den * 10) / 10;
                      }
                      return (
                        <div className={classes.focusedStat} key={stat.label}>
                          <div>{stat.label}</div>
                          <div>{val}</div>
                        </div>
                      );
                    })}
                </div>

              )}
              <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                  <Typography className={classes.subHeader}>{ `${ useSeasonStats ? "Seasons Chart - Hit Rate:" : "Game Log Chart - Hit Rate" } ${getHitRate(ouValue)}`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <ResponsiveContainer width="100%" height={200}>
                          <LineChart className={classes.lineChart} data={useSeasonStats ? seasons : getYear(year, gameLog, gameLog2023)} >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={useSeasonStats ? "season" : xAxis}/>
                            <YAxis yAxisId='left' type="number" domain={[0, Math.max.apply(Math, (useSeasonStats ? [ ...seasons, { [type]: ouValue } ] : getYear(year, gameLog, gameLog2023) || []).map((o) => o[type] ))]} />
                            <ReferenceLine yAxisId='left' y={ouValue} label="O/U" stroke="red" />
                            {/* <Tooltip content={<CustomTooltip />}/> */}
                            <Legend formatter={renderColorfulLegendText}/>
                            <Line yAxisId='left' type="monotone" dataKey={type} stroke="#51ade5" />
                          </LineChart>
                      </ResponsiveContainer>
                  </AccordionDetails>
              </Accordion>
              { oppStats && 
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.subHeader}>{oppStats.teamName}: Stats</Typography>
                    </AccordionSummary>
                    <AccordionDetails  style={{ padding: "0px" }}>
                        {/* <h4 className={classes.subHeader}>{oppStats.teamName}</h4> */}
                        <SwipeableTabs tabs={['OVR DEF','DEF v. POS']} icons={[<DataUsageIcon/>, <TrackChangesIcon/>]}>
                            <div className={classes.rankGrid}>
                                { teamRanks.map(cat => <RankCircle key={cat.rank} rank={oppStats.team[cat.rank]} teams={32} label={cat.label} value={oppStats.team[cat.value]} /> )}
                            </div>
                            <div className={classes.rankGrid}>
                              { meta.map(cat => <RankCircle key={cat.rank} rank={oppStats.pos[cat.rank]} teams={32} label={cat.label} value={oppStats.pos[cat.value]} /> )}
                            </div>
                            <div style={{height: '400px'}}>
                                <Radar data={oppStats} meta={meta}/>
                            </div>
                        </SwipeableTabs>
                    </AccordionDetails>
                </Accordion>
            }
              { lineup && <MLBOpponent lineup={lineup} PH={PH} oppStats={data.opp} /> }
              <Accordion defaultExpanded={true}>
                  <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  >
                  <Typography className={classes.subHeader}>{ useSeasonStats ? "Season Stats" : "Game Log" }</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <table className='gamelog'>
                      <thead>
                        { renderTableHeaders(sport, type, headers) }                                
                      </thead>
                      <tbody>
                        { (useSeasonStats ? seasons : getYear(year, gameLogDesc, gameLog2023)).map(game => renderGameLogTable(game, sport, type, columns, ouValue, classes)) }
                      </tbody>
                    </table>
                  </AccordionDetails>
              </Accordion>
              { seasonStats && 
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  >
                  <Typography className={classes.subHeader}>Season Stats</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      {seasonStats && (
                          <table className='gamelog'>
                            <thead>
                              { renderTableHeaders(sport, type, ['Season', 'GS', 'IP', 'ERA', 'WHIP', 'K', 'K9']) }                                
                            </thead>
                            <tbody>
                              { [
                                  ...seasonStats.reduce((accumulator, current) => {
                                    // Combine seasons that span multiple teams into one row
                                    if(accumulator[accumulator.length - 1] && current.season === accumulator[accumulator.length - 1].season) {
                                      accumulator.pop()
                                      return [...accumulator, current];
                                    } 
                                    else return [...accumulator, current];
                                  }, []),
                                  {'season': 'Career', ...careerStats }
                                ].map(game => 
                                    renderGameLogTable(game, sport, type, 
                                      ['season', 'gamesStarted', 'inningsPitched', 'era', 'whip', 'strikeOuts', 'strikeoutsPer9Inn'],
                                      null, classes)
                              )}
                            </tbody>
                          </table>
                      )}
                  </AccordionDetails>
                </Accordion>
              }
            
              { playerNews && <Accordion defaultExpanded={true} className={classes.accordion}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                  >
                  <Typography className={classes.subHeader}>Player News</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.detail} dangerouslySetInnerHTML = {{__html: playerNews}} />
                  </AccordionDetails>
              </Accordion> }
              { playerAnalysis && <Accordion defaultExpanded={true} className={classes.accordion}>
                  <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  >
                  <Typography className={classes.subHeader}>Player Analysis</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.detail} dangerouslySetInnerHTML = {{__html: playerAnalysis}} />
                  </AccordionDetails>
              </Accordion> }
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default CustomizedDialogs;
